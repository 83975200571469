var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-container",on:{"mouseover":function($event){_vm.mostrarChaves = true},"mouseleave":function($event){_vm.mostrarChaves = false}}},[_c('div',{staticClass:"fundo"}),_vm._l((_vm.listaBlocos),function(bloco,index){return _c('span',{key:index,staticClass:"bloco",on:{"mouseover":function($event){return _vm.handleHover(index, 'over')},"mouseleave":function($event){return _vm.handleHover(index, 'leave')}}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.mostrarChaves)?_c('div',{staticClass:"box-chave",class:{
          ativo: _vm.status[index] != 'erro' || _vm.mostrarCorpo[index] == true,
          inativo: _vm.status[index] == 'erro',
        },attrs:{"id":'bloco' + index},on:{"click":function($event){return _vm.scrollToBlock(bloco, index)}}},[_c('div',{staticClass:"bloco-name",class:{
            ativo: _vm.verificaBloco(bloco) || _vm.mostrarCorpo[index] == true,
          }},[_vm._v(" "+_vm._s(bloco.palavraChave)+" ")])]):_vm._e()]),_c('span',{staticClass:"box-icon"},[_c('img',{staticClass:"bloco-image",class:{
          ativo: _vm.verificaBloco(bloco),
          hover: _vm.mostrarCorpo[index] == true,
        },attrs:{"src":bloco.imagemSemBorda},on:{"click":function($event){return _vm.scrollToBlock(bloco, index)},"mouseover":function($event){return _vm.handleHover(index, 'over')},"mouseleave":function($event){return _vm.handleHover(index, 'leave')}}})])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }