<template>
  <div
    class="menu-container"
    @mouseover="mostrarChaves = true"
    @mouseleave="mostrarChaves = false"
  >
    <div class="fundo"></div>
    <span
      class="bloco"
      v-for="(bloco, index) in listaBlocos"
      :key="index"
      @mouseover="handleHover(index, 'over')"
      @mouseleave="handleHover(index, 'leave')"
    >
      <transition name="slide-fade">
        <div
          v-if="mostrarChaves"
          class="box-chave"
          :id="'bloco' + index"
          :class="{
            ativo: status[index] != 'erro' || mostrarCorpo[index] == true,
            inativo: status[index] == 'erro',
          }"
          @click="scrollToBlock(bloco, index)"
        >
          <div
            class="bloco-name"
            :class="{
              ativo: verificaBloco(bloco) || mostrarCorpo[index] == true,
            }"
          >
            {{ bloco.palavraChave }}
          </div>
        </div>
      </transition>
      <span class="box-icon">
        <img
          class="bloco-image"
          :src="bloco.imagemSemBorda"
          :class="{
            ativo: verificaBloco(bloco),
            hover: mostrarCorpo[index] == true,
          }"
          @click="scrollToBlock(bloco, index)"
          @mouseover="handleHover(index, 'over')"
          @mouseleave="handleHover(index, 'leave')"
        />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "MenuLateralSuperbox",

  data() {
    return {
      fundo: "/img/superbox/bloco-fundo-pequeno.png",
      mostrarCorpo: new Array(this.listaBlocos.length).fill(false),
      mostrarChaves: false,
    };
  },

  props: {
    listaBlocos: Array,
    status: Array,
    refAtual: String,
  },

  methods: {
    handleHover(index, acao) {
      if (acao == "over") {
        this.$set(this.mostrarCorpo, index, true);
      } else if (acao == "leave") {
        this.$set(this.mostrarCorpo, index, false);
      }
    },

    scrollToBlock(bloco, index) {
      this.$emit("scroll-to-block", index, bloco);
    },

    verificaBloco(bloco) {
      const descricaoBloco = bloco.descricao.replace(/\s/g, "");

      return this.refAtual.includes(descricaoBloco);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  position: fixed;
  height: 410px;
  width: 60px;
  right: 10px;
  top: 18%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  z-index: 10;
}

.fundo {
  position: absolute;
  background-color: white;
  height: calc(100% + 20px);
  width: 55px;
  top: -10px;
  right: 0px;
  border-radius: 30px;
  z-index: 7;
}

.bloco {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 45px;
  position: relative;
  margin-right: 7.25px;
  cursor: pointer;
}

.box-chave {
  width: 88px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: end;
  font-size: 11px;
  transition: 0.2s;
  position: relative;
  z-index: 5;
  // left: 5px;
  cursor: pointer;
  position: absolute;
  right: 35px;

  &.mb {
    margin-bottom: 40px !important;
  }

  .bloco-name {
    justify-content: flex-end;
  }
}

.bloco-title {
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  width: 100%;
}

.bloco-name {
  display: flex;
  align-items: center;
  line-height: 10px;
  width: 100%;
  border-radius: 30px;
  height: 35px;
  padding: 0 20px 0 15px;
  position: relative;
  background-color: white;
  color: #1da0f2bb;
  cursor: pointer;
  z-index: 10;

  &.ativo {
    color: #1da0f2;
    width: 100%;
    padding: 0 20px 0 0px;
    font-weight: 500;
  }

  &:hover {
    color: #1da0f2;
  }
}

.box-icon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;

}

.bloco-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  transition: 0.3s;
  border: 3px solid #1da0f281;
  border-radius: 50%;
  position: relative;

  &.ativo {
    border: 3px solid #1da0f2;
    transform: scale(1.2);
  }

  &.hover {
    transform: scale(1.2);
    border: 3px solid #1da0f2bb;
  }

  &:hover {
    transform: scale(1.2);
    border: 3px solid #1da0f2bb;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(40px);
}

.slide-fade-enter-to {
  opacity: 0.5;
  transform: translateX(0);
}

.slide-fade-leave {
  opacity: 0.5;
  transform: translateX(0);
}

.slide-fade-leave-active {
  transform: translateX(40px);
  opacity: 0;
}
</style>
