<template>
  <div class="header" :class="{ '-vert': abreBloco }">
    <div class="bloco" :class="{ '-vert': abreBloco }">
      <h3 class="bloco-text">{{ bloco.titulo }}</h3>
      <img class="title-image" :class="{ '-vert': abreBloco }" :src="bloco.imagem" />
    </div>
    <div class="title" :class="{ '-vert': abreBloco }">
      <h3 class="title-text" :class="{ '-vert': abreBloco }">{{ bloco.descricao }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBlocos",
  data() {
    return {};
  },
  props: {
    bloco: Object,
    abreBloco: Boolean
  },
};
</script>

<style lang="scss" scoped>

</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
