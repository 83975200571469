<template>
  <span class="box-checks">
    <img v-if="valor && valor != null" class="check" :src="iconCheckGreen" />
    <img v-if="!valor || valor == null" class="check" :src="iconCheckGray" />
    <img v-if="!valor && valor != null" class="check" :src="iconXRed" />
    <img v-if="valor || valor == null" class="check" :src="iconXGray" />
  </span>
</template>

<script>
export default {
  name: "Check",

  props: {
    valor: Boolean,
  },

  data() {
    return {
      iconCheckGreen: "/img/superbox/check-green.png",
      iconCheckGray: "/img/superbox/check-gray.png",
      iconXGray: "/img/superbox/x-gray.png",
      iconXRed: "/img/superbox/x-red.png",
    };
  },

  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.box-checks {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check {
  height: 25px;
  margin-right: 3px;
  margin-left: 3px;
}
</style>
